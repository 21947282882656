:root {
  --text-color: #4f6696;
  --input-color: #f7fafc;
  --title-color: #0d121c;
  --background-color: #e8ebf2;
  --border-color: #d1d6e8;
  --button-color: #3670e8;
  --toastify-color-success: #3670e8;
  --toastify-color-info: #4f6696;
  --commentsBorder-color: #79747e;
}
