.multiSlider * {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}
.multiSlider {
  display: flex;
  position: relative;
  border: none;
  border-radius: 10px;
  padding: 20px 10px;
  box-shadow: none;
  flex-direction: column;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge,*/
}
.multiSlider .bar {
  display: flex;
}
.multiSlider .bar-left {
  width: 25%;
  background-color: var(--border-color);
  border-radius: 10px 0px 0px 10px;
  padding: 2px 0px;
}
.multiSlider .bar-right {
  width: 25%;
  background-color: var(--border-color);
  border-radius: 0px 10px 10px 0px;
}
.multiSlider .bar-inner {
  background-color: lime;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-between;
  position: relative;
  justify-content: space-between;
}
.multiSlider .bar-inner-left {
  width: 50%;
}
.multiSlider .bar-inner-right {
  width: 50%;
}
.multiSlider .thumb {
  background-color: white;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.multiSlider .thumb::before {
  content: "";
  background-color: inherit;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  z-index: 1;
  margin: -7px -12px;
  cursor: pointer;
}

input:focus + .thumb::before {
  border: 1px solid var(--button-color);
}

.multiSlider .caption {
  position: absolute;
  bottom: -20px;
  left: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--title-color);
}
.multiSlider .thumb .caption * {
  position: absolute;
}
.multiSlider .caption {
  display: flex;
}

.multiSlider .input-type-range {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  opacity: 0;
  pointer-events: none;
}

.multiSlider:focus,
.multiSlider:focus-visible {
  outline: 1px solid var(--border-color);
}
