.agreementContainer {
  line-height: 1.5em;
}

.title {
  text-align: center;
  margin-bottom: 10px;
}

.agreementContainer p {
  margin-bottom: 15px;
}

.agreementContainer ol {
  margin: 20px 0;
}

.agreementContainer li {
  margin-bottom: 10px;
}

.ol {
  padding: 0 20px;
}

.li {
  display: list-item;
  list-style-type: lower-alpha;
}

.olNested {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.olNested > .liNested {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

.olNested > .liNested:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

/* .liNested .olNested > .liNested {
  margin: 0;
} */

.liNested .olNested > .liNested:before {
  content: counters(item, ".") " ";
  /* padding-right: 10px; */
}

.liNested {
  font-size: x-large;
}

.liNested.nested {
  font-size: medium;
}
