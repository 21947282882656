@import "../../stylesheets/colors.css";

.title {
  font-family: Inter;
  font-size: 22px;
  font-weight: 700;
  line-height: 27.5px;
  letter-spacing: -0.33px;
  text-align: center;
  margin-bottom: 12px;
  color: var(--title-color);
}

.registerForm {
  margin: 0 auto;
  width: 512px;
}

.itemsContainer {
  padding: 12px 16px;
}

.itemsContainer:first-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label {
  display: block;
  position: relative;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--title-color);
}

.input {
  display: block;
  box-sizing: border-box;
  width: 480px;
  margin-top: 8px;
  padding: 15px 17px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background: var(--input-color);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  color: var(--text-color);
}

.input.short {
  width: 232px;
}

.input.error {
  border-color: red;
}

.paragraph {
  padding: 4px 16px 12px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--text-color);
  word-wrap: break-word;
}

.paragraph.centered {
  text-align: center;
  width: 480px;
}

.submit {
  display: block;
  margin: 12px auto;
  width: 480px;
  padding: 9.5px 16px;
  background-color: var(--button-color);
  color: var(--input-color);
  border-radius: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.2px;
}

.returnDashboard {
  display: block;
  text-align: center;
  padding: 4px 16px 12px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: var(--text-color);
}

.returnDashboard:hover {
  font-weight: 700;
}

.errorMessage {
  position: absolute;
  color: red;
  font-size: 12px;
}
