.sortContainer {
  width: 921px;
}

.title {
  padding: 0 16px 16px;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.8px;
}

.description {
  padding: 4px 16px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sortItemContainer {
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  /* position: relative; */
}

.sortLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  flex-grow: 1;
}

.sortLabelText {
  width: 213px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--title-color);
}

.rangeContainer {
  position: relative;
  flex-grow: 1;
}
.sortRange::-moz-range-track {
  flex-grow: 1;
  background-color: var(--button-color);
  height: 4px;
}
.sortRange::-webkit-slider-runnable-track {
  flex-grow: 1;
  background-color: var(--button-color);
  height: 4px;
}

.sortRange::-webkit-slider-thumb {
  width: 16px;
  height: 16px;
  background-color: var(--button-color);
  border-radius: 50%;
  cursor: pointer;
  transform: translateY(-40%);
}
.sortRange::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background-color: var(--button-color);
  border-radius: 50%;
  cursor: pointer;
}

.importance {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.itemValue {
  position: absolute;
  top: 24px;
  left: 50%;
  font-size: 14px;
}

.submitButton {
  margin: 16px;
  padding: 9.5px 16px;

  border-radius: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: var(--input-color);
  background-color: var(--button-color);
}

.submitButton:hover {
  background-color: var(--title-color);
}
