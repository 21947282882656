.categoriesContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
  margin-bottom: 12px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.categoriesItemContainer {
  display: flex;
  padding: 0;
  width: 242px;
  height: 48px;
}

.labelCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
}

.showButton {
  display: block;
  color: var(--button-color);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
}

.showButton:hover {
  font-weight: 600;
}

.showButton:hover,
.showButton:focus {
  background-color: white;
}

.checkbox {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: var(--button-color);
  display: grid;
  place-content: center;
  flex-shrink: 0;
}

.checkbox::before {
  display: block;
  content: "";
  clip-path: polygon(14% 38%, 8% 65%, 50% 95%, 96% 10%, 78% 4%, 43% 62%);
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: var(--background-color);
  width: 10px;
  height: 10px;
}

.checkbox:checked::before {
  transform: scale(1);
}
