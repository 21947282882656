.candidatesContainer {
  width: 1065px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 27px;
}

.title {
  padding: 0 16px 8px;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.8px;
}

.subTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: var(--title-color);
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--text-color);
}

/* .buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
} */

.button {
  display: block;
  width: 154px;
  margin-right: 16px;
  padding: 5.5px 16px;
  box-sizing: border-box;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: var(--input-color);
  background-color: var(--button-color);
  border: none;
}

.button:hover {
  background-color: var(--title-color);
}

select.button {
  width: auto;
}

select.button option {
  color: var(--title-color);
  background-color: white;
}
