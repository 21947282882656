.paginator {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  border: none;
  margin-bottom: 20px;
  padding: 5px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #1e1e1e;
}

.pagesBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 8px;
  height: 16px;
}

.pagesBackground:hover {
  cursor: pointer;
}

.activeBackground {
  background-color: var(--button-color);
  color: #f5f5f5;
}

.activeBackground:hover {
  background-color: var(--title-color);
}

.ellipsisContainer {
  padding: 8px 16px;
  line-height: 22.4px;
  font-weight: 700;
}

.ellipsisContainer:hover {
  cursor: pointer;
}
