h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li {
  margin: 0;
  padding: 0;
}

ul,
ol,
li {
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  font-style: normal;
  text-decoration: none;
  border: none;
}

a:focus,
a:focus-visible {
  outline: none;
  border: none;
  box-shadow: none;
}

button {
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  display: block;
}

button:focus,
button:focus-visible {
  outline: none;
  border: none;
  box-shadow: none;
}

li {
  display: block;
}

input {
  appearance: none; /* Removes default browser styles */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px var(--background-color) inset !important;
}

input,
select,
option {
  outline: none;
  box-shadow: none;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}
