.filtersContainer {
  flex-grow: 1;
}

.title {
  padding: 0 16px 16px;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.8px;
}

.subTitle {
  padding: 16px 16px 8px;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.5px;
  letter-spacing: -0.27px;
}

.itemsContainer {
  padding: 12px 16px;
}

.slidersContainer {
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  gap: 13px;
  align-items: center;
}

.labelCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.slider {
  flex-grow: 1;
}

.label {
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--title-color);
}

.labelSlider {
  width: 213px;
}

.input {
  display: block;
  box-sizing: border-box;
  margin-top: 8px;
  width: 480px;
  padding: 16px;
  background-color: var(--background-color);
  border-radius: 12px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--text-color);
}

.input.error {
  border-color: red;
}

.textarea {
  height: calc(24px * 5);
  outline: none;
}

.nextButtton {
  display: block;
  margin: 36px 16px;
  width: 480px;
  padding: 9.5px 16px;
  background-color: var(--button-color);
  color: white;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.2px;
}

.checkbox {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: var(--button-color);
  display: grid;
  place-content: center;
}

.checkbox::before {
  display: block;
  content: "";
  clip-path: polygon(14% 38%, 8% 65%, 50% 95%, 96% 10%, 78% 4%, 43% 62%);
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: var(--background-color);
  width: 10px;
  height: 10px;
}

.checkbox:checked::before {
  transform: scale(1);
}

.experienceTitle {
  padding-bottom: 12px;
  font-weight: 700;
}

.categoryTitle {
  padding-bottom: 4px;
  font-weight: 700;
}

.experienceItemContainer {
  padding: 4px 0;
}

.errorMessage {
  position: absolute;
  color: red;
  font-size: 12px;
}

.multiple {
  overflow-y: hidden;
}

.categoryDescription {
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  padding-bottom: 12px;
}

.magicButton,
.magicButton:focus {
  width: 43px;
  height: 45px;
  background-image: url("../../images/magic-wand_norm.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.magicButton:hover {
  background-image: url("../../images/magic-wand_hover.png");
  background-color: transparent;
}

.magicButton:disabled {
  background-image: url("../../images/magic-wand_inactive.png");
  background-color: transparent;
  cursor: not-allowed;
}
