.menuContainer {
  display: block;
  position: absolute;
  top: 54px;
  right: 16px;
  z-index: 200;
  padding: 14px;
  border-radius: 12px;
  background-color: #e8ebf2;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.5px;
  letter-spacing: -0.27px;
}

.menuItem:hover {
  font-weight: 700;
}
