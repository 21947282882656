.searchItem {
  display: flex;
  flex-direction: row;
  padding: 14px 16px 13px;
  justify-content: space-between;
  border: 1px solid var(--background-color);
  border-radius: 12px;
  margin-bottom: 2px;
}

.searchItem.inactive,
.searchOpener.inactive {
  pointer-events: none;
  opacity: 0.5;
}

.searchOpener {
  cursor: pointer;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.button {
  height: auto;
  align-self: center;
  padding: 5.5px 16px;
  color: var(--title-color);
  background-color: var(--background-color);
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.button:hover {
  font-weight: 700;
  background-color: var(--background-color);
}

.position {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--title-color);
}

.position:hover {
  font-weight: 700;
}

.city {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--text-color);
}

.iconSettings {
  width: 20px;
  height: 20px;
}
