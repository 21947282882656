.table {
  width: 100%;
  /* border-collapse: collapse; */
  table-layout: auto;
  border-radius: 12px;
  border: 1px solid var(--border-color);
  padding: 0 8px;
  margin-bottom: 20px;
}

.table th,
.table td {
  text-align: center;
  border-bottom: 1px solid var(--border-color);
}

.table tr:last-child td {
  border-bottom: none;
}

.table th {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: var(--title-color);
  height: 46px;
  padding: 0 4px;
}

.table td {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: black;
}

.commentContainer {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Make sure the container has a height to center items vertically */
  position: relative;
  min-height: 46px;
  padding: 7px;
  box-sizing: border-box;
}

.textarea {
  box-sizing: border-box;
  border: 1px solid var(--commentsBorder-color);
  border-radius: 8px;
  overflow: hidden;
  resize: none;
  padding: 6px 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  /* letter-spacing: -0.9px; */
}

.textareaCollapsed {
  width: 122px;
  height: 32px;
}

.textareaExpanded {
  width: 200px;
  height: 100px;
  position: absolute; /* Overlapping position */
  top: 0; /* Adjust positioning as needed */
  left: 0; /* Adjust positioning as needed */
  z-index: 10; /* Ensure it overlaps other elements */
}
