.searchesContainer {
  padding: 0 120px;
}

.title {
  padding: 0 16px 16px;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.8px;
  text-align: left;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px 12px 12px;
}

.buttonsList {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 12px;
  align-items: center;
}

.filterButton {
  display: block;
  padding: 5.5px 16px;
  background-color: var(--background-color);
  border-radius: 12px;
  color: var(--title-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.filterButton:hover,
.filterButton:focus,
.filterButton:focus-visible,
.filterButton.active {
  background-color: var(--background-color);
  font-weight: 700;
}

.addButtton {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 9.5px 16px;
  align-items: center;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: var(--input-color);
  background-color: var(--button-color);
}

.addButtton:hover {
  background-color: var(--title-color);
}

.iconSettings {
  width: 20px;
  height: 20px;
}

/* .filterButton.active {
  color: red;
} */
