.footerSection {
  flex-grow: 1;
  display: flex;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.growing {
  flex-grow: 1;
}
.footerItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-bottom: 40px;
}
