.detailsContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.navigation {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 275px;
}

.navItem {
  padding: 8px 12px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: var(--title-color);
}
