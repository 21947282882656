.title {
  font-family: Inter;
  font-size: 22px;
  font-weight: 700;
  line-height: 27.5px;
  letter-spacing: -0.33px;
  text-align: center;
  margin-bottom: 12px;
}

.loginForm {
  margin: 0 auto;
  width: 512px;
}

.label {
  display: block;
  padding: 12px 16px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.input {
  display: block;
  box-sizing: border-box;
  width: 480px;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background-color: var(--background-color);
  border-radius: 12px;
  margin-top: 8px;
}

.input.error {
  border-color: red;
}

.submit {
  display: block;
  margin: 12px auto;
  width: 480px;
  padding: 9.5px 16px;
  background-color: var(--button-color);
  color: white;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.2px;
}

.submit:hover,
.submit:focus,
.submit:focus-visible {
  background-color: var(--title-color);
}

.navLinks {
  display: block;
  margin: 0 auto;
}

.navLink {
  display: block;
  margin: 0 auto;
  padding: 4px 0 12px;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.errorMessage {
  position: absolute;
  color: red;
  font-size: 12px;
}
