@import "../../stylesheets/colors.css";

.paySection {
  text-align: center;
}

.title {
  font-family: Inter;
  font-size: 22px;
  font-weight: 700;
  line-height: 27.5px;
  letter-spacing: -0.33px;
  text-align: center;
  margin-bottom: 24px;
  color: var(--title-color);
}

.price {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.fee {
  font-size: 32px;
  font-weight: 700;
  line-height: 34px;
  padding: 10px 0;
}

.rangeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.monthsCount {
  margin-top: 24px;
  margin-bottom: 12px;
}

.months {
  display: flex;
  flex-direction: row;
  width: 420px;
  margin-bottom: 24px;
}

.monthsLimits {
  padding: 0 8px;
}

.sortRange::-moz-range-track {
  flex-grow: 1;
  background-color: var(--button-color);
  height: 4px;
}
.sortRange::-webkit-slider-runnable-track {
  flex-grow: 1;
  background-color: var(--button-color);
  height: 4px;
}

.sortRange::-webkit-slider-thumb {
  width: 16px;
  height: 16px;
  background-color: var(--button-color);
  border-radius: 50%;
  cursor: pointer;
  transform: translateY(-40%);
}
.sortRange::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background-color: var(--button-color);
  border-radius: 50%;
  cursor: pointer;
}

.button {
  margin: 0 auto;
  padding: 9.5px 16px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: var(--input-color);
  background-color: var(--button-color);
}

.openButton:hover {
  background-color: var(--title-color);
}

.requisitesContainer {
  padding: 32px 24px;
}

.requisiteText {
  line-height: 1.5em;
  margin-bottom: 24px;
}
