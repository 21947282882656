.privacyContainer {
  line-height: 1.5em;
}

.title {
  text-align: center;
  margin-bottom: 40px;
}

.privacyContainer p,
.privacyContainer ul {
  margin: 10px 0;
}

.listHeaders {
  font-size: larger;
}

.mainList > li {
  margin-bottom: 20px;
}

.bulletsList {
  padding-left: 40px;
}

.bulletsList li {
  display: list-item;
  list-style-type: disc;
}
