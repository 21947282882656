.headerSection {
  margin-bottom: 36px;
}

.headerContainer {
  border-bottom: 1px #e5e8eb solid;
}

.headerInnerContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 65px;
  align-items: center;
  justify-content: space-between;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.iconLogo {
  width: 16px;
  height: 16px;
}

.logo {
  font-size: 18px;
  font-weight: 700;
  line-height: 22.5px;
  letter-spacing: -0.27px;
  text-align: left;
}

.userContainer {
  display: flex;
  flex-direction: row;
  height: 40px;
  gap: 32px;
}

.button {
  border-radius: 12px;
}

.iconSettings {
  width: 40px;
  height: 40px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.backDrop {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
}
