@import-normalize;
@import "./stylesheets/multirangeSlider.css";
@import "./stylesheets/reset.css";

html {
  box-sizing: border-box;
  width: 100vw;
  overflow-x: hidden;
  background-color: #e7eaf2;
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  font-family: "Inter", sans-serif;
  text-decoration: none;
  margin: 0;
  padding: 0 0 0 0;
  background: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: auto;
}

#root {
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.container {
  margin: 0 auto;
  padding: 0 40px;
  box-sizing: border-box;
  /* outline: 1px red solid; */
}

@media screen and (min-width: 1440px) {
  .container {
    padding: 0 40px;
    max-width: 1440px;
  }
}

input,
select,
textarea {
  border: 1px solid var(--border-color);
}

input:focus,
input:focus-visible,
select:focus,
select:focus-visible,
textarea:focus,
textarea:focus-visible {
  border: 1px solid var(--button-color);
}

button:hover,
button:focus,
button.focus-visible {
  background-color: var(--title-color);
}

a:hover,
a:focus,
a:focus-visible,
a.active {
  font-weight: 700;
}

select option,
textarea {
  font-family: "Inter", sans-serif;
}

a.active {
  background: var(--background-color);
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 0;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0 !important;
  border-radius: 0 !important;
}
